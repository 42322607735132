// link-farbe der webseite
// @linkColor: rgb(63, 200, 244);

@primaryColor: #143e4b;
@linkColor: rgb(35, 117, 144);
@linkHoverColor: lighten(@linkColor, 10%);

:root {
	--primary-color: @primaryColor;
	--primary-highlight-color: lighten(@primaryColor, 10%);
	--primary-disabled-color: lighten(@primaryColor, 20%);

	--link-color: @linkColor;
	--link-hover-color: @linkHoverColor;
}

#headerlogo {
	background-image: url(logo.png);
	background-size: auto 45px;
	background-repeat: no-repeat;
	background-position: left center;
}
